
<!--  定制开发 -->
<template>
  <div class="customDev">
    <product-banner :bannerImg="bannerImg" :bannerTitleData="bannerTitleData" />
    <cases-title :titleVal="'定制开发'" />
    <div class="customDev-container">
      <div class="first-text">宝晟拥有先进的软件技术平台和高水平实施顾问，根据每一个用户个体的信息化管理需求，量身定制出一套符合该用户实际应用的信息管理系统软件。</div>
      <div class="item-container" v-for="(item, index) in contentData " :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="desc">{{ item.desc }}</div>
        <img v-show="item.img" :src="item.img" alt="">
      </div>
    </div>
  </div>
</template>

    <script>
import productBanner from './components/ProductsBanner.vue'
import casesTitle from "@/views/cases/components/Title.vue"
export default {
  components: {
    productBanner,
    casesTitle
  },
  data() {
    return {
      bannerImg: require("@/assets/images/products/products-customDev.png"),
      bannerTitleData: {
        zn: "定制开发",
        en: "Customization Services"
      },
      contentData: [
        {
          title: "针对性强",
          desc: "每一套系统的开发都要经过细致的系统性分析，针对不同用户的实际业务工作情况，编制最合适的程序，实现最适用的功能。在开发实施过程中，将行业的最新管理思路或者最科学的管理模式融入到软件的数学模型中，从而大大提高每一套信息管理系统的科学价值，为用户带来最大的的经济效益。",
          img: require("@/assets/images/products/customDev-1st.png")
        },
        {
          title: "高效便捷",
          desc: "信息系统完全根据用户的业务工作流程开发，从业务流程的每个节点，用户的每个岗位置和操作习惯，甚至每个使用系统工作人员的工作台界面，进行细致的设计，完美解决“人适应软件”还是“软件适应人”的矛盾，用户只需具备基本的计算机使用知识，就可以自如的使用系统，线下线上操作良好匹配。流畅进行，极大地提高工作效率。",
          img: require("@/assets/images/products/customDev-sec.png")
        },
        {
          title: "平台化",
          desc: "提供可扩展的二次开发平台，作为软件引擎，可基于平台实现N套应用系统共用组织中心、流程中心、应用中心、表单中心与消息中心，平台具有良好的开放性，对不同应用软件供应商的应用或功能系统进行模块化插拔式升级和替换（二次开发），对业务进行统一，避免系统间重复造轮子，以帮助用户搭建一个企业级信息化平台。",
          img: null
        },
        {
          title: "后续服务保障",
          desc: "定制开发软件灵活度高，局限性小，用户因为业务发展，业务调整，需要对系统进行优化，对业务流程进行变更、调整，以及拓展系统应用功能等服务需求，可以得到最低成本，最大效果的后续服务保障。",
          img: null
        },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.customDev-container {
  width: 1200px;
  margin: 0 auto;

  .first-text {
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    text-align: justify;
  }

  .item-container {
    text-align: center;

    &:last-child {
      margin-bottom: 46px;
    }


    .title {
      text-align: left;
      height: 30px;
      font-size: 22px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 30px;
      margin: 44px 0 16px;
    }


    .desc {
      text-align: left;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      letter-spacing: 1px;
      text-align: justify;
    }

    img {
      margin: 26px 0;
    }

  }

}
</style>
